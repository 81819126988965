import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const GTMProvider = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    // Initialize dataLayer
    window.dataLayer = window.dataLayer || [];

    // GTM script
    const script = document.createElement('script');
    script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-52MBN7V');`;

    document.head.appendChild(script);

    // Add noscript iframe
    const noscript = document.createElement('noscript');
    const iframe = document.createElement('iframe');
    iframe.src = 'https://www.googletagmanager.com/ns.html?id=GTM-52MBN7V';
    iframe.height = '0';
    iframe.width = '0';
    iframe.style.display = 'none';
    iframe.style.visibility = 'hidden';
    noscript.appendChild(iframe);
    document.body.insertBefore(noscript, document.body.firstChild);
  }, []);

  // Track page views
  useEffect(() => {
    if (location.pathname !== '/zamow-list') {
      window.dataLayer.push({
        event: 'pageview',
        page: location.pathname + location.search
      });
    }
  }, [location]);

  return <>{children}</>;
};

// Hook dla eCommerce event'ów
export const useEcommerce = () => {
  const pushPurchaseEvent = (purchaseData) => {
    window.dataLayer.push({
      event: 'purchase',
      ecommerce: {
        currencyCode: 'PLN',
        purchase: {
          actionField: {
            id: purchaseData.transactionId,
            affiliation: 'FDDS',
            revenue: purchaseData.revenue,
            tax: purchaseData.tax || '0',
            shipping: '0.00'
          },
          products: [{
            name: purchaseData.productName,
            id: purchaseData.productId,
            price: purchaseData.price,
            brand: 'FDDS',
            category: purchaseData.isMonthly ? 'Miesięcznie' : 'Jednorazowe',
            quantity: 1
          }]
        }
      }
    });
  };

  return { pushPurchaseEvent };
};

export default GTMProvider;
