import React from "react";
import ReactGA from "react-ga";
import { Link, useParams } from "react-router-dom";
import {useEcommerce} from "../../features/gtm-provider";
const ThankYou = () => {
 let { letterId, amount } = useParams();
  const { pushPurchaseEvent } = useEcommerce();

  // Wysyłamy dane do GTM przy pierwszym renderze
  React.useEffect(() => {
    pushPurchaseEvent({
      transactionId: `LETTER-${letterId}-${Date.now()}`,
      revenue: amount || '0',
      productName: 'List do Mikołaja',
      productId: letterId,
      price: amount || '0',
      isMonthly: false
    });
  }, [letterId, amount]);

  // Zachowujemy oryginalną funkcję dla ReactGA
  const collectGaEvent = () => {
    // Istniejący kod ReactGA - pozostaje bez zmian
    ReactGA.event({
      category: 'Wyślij list',
      action: "Dziękujemy za zamówienie",
      label: "Wyślij kolejny list",
    });

    // Dodajemy równoległy event do GTM
    window.dataLayer.push({
      event: 'list_order_another',
      category: 'Wyślij list',
      action: "Dziękujemy za zamówienie",
      label: "Wyślij kolejny list"
    });
  };
  return (
    <>
      <div className="container-fluid subpage thankyou">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h2 className="text-center">
                DZIĘKUJEMY!
                <br />
                List został wysłany do pracowni Mikołaja
              </h2>
              <h3 className="text-center">
                Na Twoją skrzynkę pocztową wysłaliśmy potwierdzenie zamówienia. <br />
                Mamy nadzieję, że list sprawi wiele radości Adresatowi!
              </h3>
              <Link
                onClick={collectGaEvent}

                to="/zamow-list"
                className="wyslij_small ng-scope"
                style={{
                  position: "static",
                  display: "inline-block",
                  color: "#fff",
                }}
              >
                {" "}
                Wyślij kolejny list
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ThankYou;
