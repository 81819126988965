import React, { useEffect } from "react";

import classNames from "classnames";
import { Controller, useWatch } from "react-hook-form";
import Select from "react-select";

const LetterFieldSelect = ({
  letterField,
  control,
  register,
  errors,
  setValue,
  touchedFields,
  unregister,
}) => {
  const formWatch = useWatch({
    control,
  });

  const conditional_field =
    letterField.conditional_letter_field &&
    letterField.conditional_letter_field;

  const conditional_field_change =
    conditional_field &&
    formWatch &&
    formWatch["field_" + conditional_field.id];

  const curretFieldWatch = formWatch["field_" + letterField.id];
  const conditionalWasTouched = conditional_field
    ? touchedFields["field_" + conditional_field.id]
    : null;

  useEffect(() => {
    if (
      conditionalWasTouched

      // pole ma być wyczyszczone kiedy zmienia się conditional ale tylko wtedy kiedy robione jest to recznie
    ) {
      setValue("field_" + letterField.id, null);
    }
  }, [conditional_field_change, letterField, setValue, conditionalWasTouched]);

  const options = [];

  letterField.letter_field_options.forEach((fieldOption) => {
    const hastagregex = /#\S*/gm;
    const hasInsideReplacer = hastagregex.test(fieldOption.label);
    fieldOption = {
      ...fieldOption,
      label: fieldOption.label.replace(hastagregex, "______"),
      hasInsideReplacer: hasInsideReplacer,
    };
    console.log(fieldOption);
    if (!fieldOption.conditional_letter_field_option) {
      // if option is not conditional just add
      options.push({
        value: fieldOption.id,
        label: fieldOption.label,
        open: fieldOption.is_open,
        display: fieldOption.value,
        hasInsideReplacer: fieldOption.hasInsideReplacer,
      });
    } else {
      // option is conditional

        if (
        (conditional_field && formWatch["field_" + conditional_field.id] &&
          formWatch["field_" + conditional_field.id].value) ===
        fieldOption.conditional_letter_field_option.id // conditional select has conditional option selected
      ) {
        options.push({
          value: fieldOption.id,
          label: fieldOption.label,
          open: fieldOption.is_open,
          display: fieldOption.value,
          hasInsideReplacer: fieldOption.hasInsideReplacer,
        });
      }
    }
  });
  useEffect(() => {
    if (curretFieldWatch) {
      for (var o in options) {
        const opt = options[o];
        if (!curretFieldWatch.open && opt.open) {
          unregister(letterField.id + "__open");
        }
      }
    }
  }, [curretFieldWatch]);
  if (conditional_field) {
    if (!formWatch["field_" + conditional_field.id]) {
      return <></>;
    }
  }
  let openPsErorr = false;
  if( errors && errors[letterField.id + "__open"] && errors[letterField.id + "__open"].type === "maxLength" ){
    openPsErorr = true
  }
  // console.log(options);
  return (
    <div className="form-group">
      <label
        className={classNames({
          error: errors["field_" + letterField.id],
        })}
      >
        {letterField.label}
      </label>
      <Controller
        name={"field_" + letterField.id}
        control={control}
        render={({ field }) => (
          <Select
            {...field}
            options={options}
            placeholder=""
            isSearchable={false}
            blurInputOnSelect={true}
          />
        )}
        rules={{ required: true }}
      />
    
      {curretFieldWatch && curretFieldWatch.open && (
        <div className="mt-4">
          <div
            className={classNames({
              error: errors[letterField.id + "__open"],
            })}
          >
            <input
              className="form-control"
              placeholder="Wpisz samodzielnie..."
              {...register(letterField.id + "__open", {
                required: true,
                maxLength: 40,
              })}
            />
            {openPsErorr && ( <div className="error">Maksymalna długość to 40 znaków</div>)}
          </div>
        </div>
      )}
    </div>
  );
};

export default LetterFieldSelect;
